import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { zippo } from "../utils/utilityFunctions";
import Layout from "../components/Layout";
import {  Container, 
          Grid, 
          makeStyles, 
          createStyles, 
          Typography,
          option,
          FormHelperText,
          FormControl,
          Select,
          TextField,
          Button,
          Modal,
          Link,
          Table,
          TableBody,
          TableCell,
          TableContainer,
          TableHead,
          TableRow,
          Paper
        }  from "@material-ui/core/";
import CancelIcon from '@material-ui/icons/Cancel';
import { StaticImage } from "gatsby-plugin-image";

import ScriptEditorImage from "../images/notes.svg";
import PhotoGalleryImage from "../images/images.svg";
import LoginImage from "../images/key.svg";

const muiStyles = makeStyles((theme) => {

  const inputTheme = {
    backgroundColor: "#2A2A2A",
    color: "#ffffff",
    borderRadius: "4px",
    border: "1px solid #ffffff",
  };

  const headingTheme = {
    color: "#850705",
    fontFamily: 'Arial',
    fontWeight: '700',
  }

  return createStyles({
    imageWrapper: {
      display: 'grid',
    },
    heroImage: {
      gridArea: '1/1',
      maxHeight: '550px',
    },
    heroOverlay: {
      opacity: '0.41',
      backgroundColor: '#FFFFFF',
      gridArea: '1/1',
    },
    heroContentWrapper: {
      gridArea: "1/1",
      position: "relative",
      placeItems: "center",
      display: "grid",
    },
    heroContent: {
      height: "100%",
    },
    heroHeadline:{
      ...headingTheme,
      textAlign: 'center',
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    heroSubHeader: {
      color: "#000000",
      textAlign: 'center',
      lineHeight: '1.1875rem',
      fontSize: '0.9907rem',
    },
    getQuoteForm: {
      textAlign: 'center',
    },
    getQuoteFormSelect: {
      ...inputTheme,
      '&& option, && optgroup': {
        ...inputTheme,
      },
      '&& .MuiSelect-icon': {
        color: '#FFFFFF',
      }
    },
    getQuoteZipcode:{
      ...inputTheme,
    },
    getQuoteFormSubmit:{
      padding: '15px 32px',
      backgroundColor: '#850705',
      borderRadius: 0,
      fontWeight: 700,
      '&&:hover': {
        backgroundColor: '#690200',
      }
    },
    inputError: {
      color: '#FFFFFF',
    },
    tipsContainer: {
      textAlign: 'center',
      padding: '30px 45px',
    },
    tipsHeading:{
      ...headingTheme,
      lineHeight: '2.375rem',
      fontSize: '1.4861rem',
    },
    p: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
    },
    articleImage: {
      gridArea: '1/1',
      height: '175px',
    },
    articleTitle: {
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'left',
    },
    articleExcerpt: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      textAlign: 'left',
    },
    articleReadMore: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      color: '#1c6bbd',
      textAlign: 'justify',
      cursor: 'pointer',
    },
    stepsHeading:{
      ...headingTheme,
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    stepTitle:{
      ...headingTheme,
      lineHeight: '1.5625rem',
      fontSize: '1.1146rem',
      marginTop: '20px',
    },
    stepText:{
      lineHeight: '1.25rem',
      fontSize: '0.8669rem',
    },
    stepCircle: {
      border: 0,
      background: 'rgb(240,243,245)',
      borderRadius: '50%',
      width: '150px',
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
    stepIcon: {
      height: '3.8125rem',
      width: '3.8125rem',
    },
    modal: {
      overflowY: 'scroll',
      padding:"50px",
      backgroundColor: 'rgba(0,0,0,0.65)',
      [theme.breakpoints.down('sm')]: {
        padding:"0px",
      },
    },
    modalWrapper: {
      padding:"50px",
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        padding:"20px",
      },
    },
    modalCloseIcon: {
      position: 'absolute',
      top: '-20px',
      right: '-5px',
      fontSize: '2.5em',
      border: '1px solid #FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
    },
    modalContent: {
      backgroundColor: '#ffffff',
      border: '0px solid #000',
      padding: '20px',
      textAlign: 'left',
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      color: '#37465A',
      borderRadius: '10px',
      position: 'relative',
      outline: 'none',
    },
    footerGetQuote: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    footerGetQuoteInputError: {
      color: 'red',
    }
  });
});

// yup validation schema -- overkill but easy to use
const schema = yup.object().shape({
  zipcode: yup
    .string()
    .min(5, "Zipcode must be 5 characters")
    .max(5, "Zipcode must be 5 characters")
    .matches(/(^\d{5}$)/, "Please enter a valid zipcode")
    .required("Zip Code is required"),
  vertical: yup.string().required("This field is required"),
});

const GetQuoteForm = ({className, inputErrorClassName, inputLayout = "default"}) => {
  const [validZip, setValidZip] = useState();
  const [zippoResponse, setZippoResponse] = useState();

  const classes = muiStyles();

  // react-hook-form useForm Hook
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (validZip) {
      const { city, state } = zippoResponse;

      // performs the redirect via Gatsby's navigate method.  We pass the state to be stored in sessionStorage on the TYP
      navigate("/thankyou/", { state: { ...data, city, state } });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const noString = value.replace(/\D/g, "");

    // prevents strings in this input
    setValue("zipcode", noString, {
      shouldValidate: true,
      shouldDirty: true,
    });    

    if (value && value.length === 5) {

      // calls the zippo API to get the city/state which gets stored in sessionStorage
      zippo(value).then((res) => {
        if (res) {
          clearErrors("zipcode");

          // zipcode input is not a controlled input so we ref the DOM to remove the class. 
          document.getElementById("zipcode").classList.remove("border-error");
          setZippoResponse(res);
          setValidZip(true);
        } else {
          setValidZip(false);
        }
      });
    }
  };

  useEffect(() => {
    // can't be a falsy check here need to check for explicit false value 
    if (validZip === false) {
      setError("zipcode", {
        type: "manual",
        message: "Please enter a valid zipcode.",
      });
    }
  }, [validZip, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Grid container justify={inputLayout === "inline" ? "center" : "left"}>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <Select
                id="quoteType"
                displayEmpty
                defaultValue=""
                native
                className={classes.getQuoteFormSelect}
                {...register("vertical")}
              >
                <option value="" disabled>
                  What are you looking for?
                </option>
                <option value="refinance">Refinance</option>
                <option value="purchase">Home Mortgage</option>
                <option value="dpa">Down Payment Assistance (DPA)</option>
                <option value="rto">Rent to Own</option>
                <optgroup label="Insurance">
                  <option value="auto">Auto Insurance</option>
                  <option value="home">Home Insurance</option>
                  <option value="life">Life Insurance</option>
                  <option value="medsup">Medicare Support</option>
                </optgroup>
                <option value="solar">Solar</option>
                <option value="windows">Windows</option>
                <option value="roofing">Roofing</option>
              </Select>
              <FormHelperText className={inputErrorClassName || classes.inputError}>{errors.vertical?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="zipcode"
                placeholder="Zip Code"
                name="zipcode"
                variant="outlined"
                {...register("zipcode")}
                onChange={handleChange}
                InputProps={{
                  className: classes.getQuoteZipcode,
                }}
                autoComplete="off"
                value={getValues("zipcode")}
              />
              <FormHelperText className={inputErrorClassName ||  classes.inputError}>{errors.zipcode?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 5} justify={inputLayout === "inline" ? "flex-end" : "flex-start"}>
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} md={inputLayout === "inline" ? 11 : 10} >
            <Button variant="contained" color="primary" type="submit" className={classes.getQuoteFormSubmit} fullWidth={inputLayout === "inline" ? true : false}>
              Get Quote
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const Hero = () => {
  const classes = muiStyles();
  return (
    <div className={classes.imageWrapper}>
      <StaticImage
        className={classes.heroImage}
        layout="fullWidth"
        alt="Homeowners Savings"
        src={
          "../images/homereliefsurvey_header.jpg"
        }
        formats={["auto", "webp", "avif"]}
      />
      <div className={classes.heroOverlay}/>
      <Container className={classes.heroContentWrapper} maxWidth="md">
        <Grid container direction="row"
          justify="flex-end"
          alignItems="flex-end" 
          className={classes.heroContent}>
          <Grid item xs={12} sm={10} md={8} lg={6} textAlign="left">
            <br/>
            <Typography variant="h1" component="h1" className={classes.heroHeadline}>
              Home Relief Survey
            </Typography>
            <p className={classes.heroSubHeader}>
              <strong>
                Let us connect you with local professionals that can help you save on home financing and insurance needs. <br/>
                <br/>
                Shop, compare and save!
              </strong>
              <br/>
            </p>
          </Grid>
          <Grid item xs={12} sm={10} md={8} lg={6} textAlign="left">
            <GetQuoteForm className={classes.getQuoteForm}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Tips = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#fbfbfb',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Typography variant="h5" component="h5" className={classes.tipsHeading}>
          Why Spend More Than You Have To? Let Us Help You Find Savings!
        </Typography>
        <p className={classes.p}>
          We can connect you with experts in your area for all your home and insurance needs.<br/>
          See if you can lower your payments today! Need help? We’ve got you covered.
        </p>
      </Container>
    </div>
  )
};

const Blogs = ( { setModalProps }) => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Grid container  spacing={6}>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="From A to Z: Commonly Used Insurance Terms and Definitions"
              src={"../images/homereliefsurvey_article1.jpg"}
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
            From A to Z: Commonly Used Insurance Terms and Definitions
            </Typography>
            <p className={classes.articleExcerpt}>
            How much do you know about insurance? It’s a world filled with words that sound difficult to understand. But if you were to start protecting what’s important to you, it’s time to brush up on your ...
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'From A to Z: Commonly Used Insurance Terms and Definitions',
                description: 'From A to Z: Commonly Used Insurance Terms and Definitions',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">From A to Z: Commonly Used Insurance Terms and Definitions</Typography>
                    <p>
                    By Justin December 19, 2017
                    </p>
                    <p>
                    How much do you know about insurance? It’s a world filled with words that sound difficult to understand. But if you were to start protecting what’s important to you, it’s time to brush up on your knowledge of insurance terms.
Yes, there’s a handful of common insurance terms and definitions that should give you a good headstart on various types of policies out there, terminology specific to the industry, and other words that could help you shop for coverage.
Is it an insurer or an insured? What’s the difference? Let’s find out.
                    </p>
                    <p>
                      <strong>Common Insurance Terms From A to Z</strong><br/>
                      We are listing down insurance terms that are commonly used or heard of and whose definitions can get you started.
                    </p>
                    <p>
                      <i>Agent.</i> He/she can be independent or connected with an insurer. Sometimes called financial advisors, insurance agents sell one or more policies and help you out in choosing the plan that’s right for you.
                    </p>
                    <p>
                      <i>Beneficiary.</i> In life insurance, this is the person or persons who will receive payment upon death of the insured (see definition below).
                    </p>
                    <p>
                      <i>Claim.</i> It is a request for reimbursement for losses incurred due to an event covered by the insurance.
                    </p>
                    <p>
                      <i>Deductible.</i> As a policyholder, you pay this dollar amount before the insurer covers the loss claimed. Within a policy, there might be a separate deductible for every loss.
                    </p>
                    <p>
                      <i>Earthquake.</i> According to the Insurance Information Institute, a standard homeowners policy does not cover earthquakes. You have to buy a separate policy for earthquake damage.
                    </p>
                    <p>
                      <i>Flood.</i> Basic homeowners insurance doesn’t cover flood. It is obtained separately through the National Flood Insurance Program (if your area participates in the program).
                    </p>
                    <p>
                      <i>Gap insurance.</i> In auto insurance, it bridges the gap between the actual cash value of the car and the unpaid balance of the loan/lease. Gap insurance figures more in newer cars that depreciate quickly.
                    </p>
                    <p>
                      <i>Health insurance.</i> This policy covers costs and expenses arising from certain physical illness and bodily injury. 
                    </p>
                    <p>
                      <i>Insured.</i> The individual, party or entity covered by an insurance policy that is issued by an insurer. In the case of named insured, this is the person who is specifically named as insured in the policy and is the policyholder.
                    </p>
                    <p>
                      <i>Justified complaint.</i> A complaint based on an expressed violation of an insurance contract provision or a relevant law or statute.
                    </p>
                    <p>
                      <i>Kidnap/ransom insurance.</i> This covers ransom and related expenses as a result of kidnapping covered high net worth individuals or key business executives and employees.
                    </p>
                    <p>
                      <i>Lapse.</i> When you are unable to pay your premiums even after a grace period, the policy is in lapse or terminated.
                    </p>
                    <p>
                      <i>Motorcycle Insurance.</i> This provides coverage to you and your motorbike.
                    </p>
                    <p>
                      <i>Named driver policy.</i> The auto policy will only cover individuals named in the contract.
                    </p>
                    <p>
                      <i>Owner occupied.</i> In homeowners insurance, it is a home that is occupied as a primary residence. Renters can still buy insurance to protect their belongings called renters insurance.
                    </p>
                    <p>
                      <i>Premium.</i> It is how much you’ll be paying to maintain your insurance coverage on a monthly, quarterly, or annual basis.
                    </p>
                    <p>
                      <i>Qualifying Event.</i> More applicable to group health insurance, this is an event that triggers coverage or special enrollment.
                    </p>
                    <p>
                      <i>Replacement Cost.</i> The cost of rebuilding a home after being damaged; not to be mistaken for the home’s market value that can fall below current construction costs.
                    </p>
                    <p>
                      <i>Standard Auto.</i> This refers to car insurance coverage for drivers deemed as standard risk, i.e. someone who can be insured at standard rates.
                    </p>
                    <p>
                      <i>Term.</i> It is the life of your policy.
                    </p>
                    <p>
                      <i>Underwriting.</i> It is the process of evaluating an individual or entity’s risk of being insured to calculate rates.
                    </p>
                    <p>
                      <i>Variable Life Insurance.</i> Its cash value is linked to investments that change over time.
                    </p>
                    <p>
                      <i>Whole Life Insurance.</i> This policy offers permanent coverage throughout the life of the insured, or until his/her death where a payout is given to the designated beneficiary.
                    </p>
                    <p>
                      That’s just for starters. Ask your insurance agent or an insurance specialist for a better understanding of insurance terms.
                    </p>
                  </React.Fragment>
                ),
              })}>READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="What Can you do to Lower Your Homeowners Insurance Premium?"
              src={
                "../images/homereliefsurvey_article2.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              What Can you do to Lower Your Homeowners Insurance Premium?
            </Typography>
            <p className={classes.articleExcerpt}>
              Homeowners insurance is required if you have a mortgage, but that doesn’t mean you have to overpay for it. While you need enough coverage to replace your home should there be a total loss, there are ... 
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'What Can you do to Lower Your Homeowners Insurance Premium?',
                description: 'What Can you do to Lower Your Homeowners Insurance Premium?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">What Can you do to Lower Your Homeowners Insurance Premium?</Typography>
                    <p>
                    By JMcHood July 6, 2018
                    </p>
                    <p>
                    Homeowners insurance is required if you have a mortgage, but that doesn’t mean you have to overpay for it. While you need enough coverage to replace your home should there be a total loss, there are ways you can lower your premium without sacrificing coverage.
                    </p>
                    <p>
                      <strong>Take a Higher Deductible</strong><br/>
                      It seems like we are automatically programmed to take the lowest deductible available. What if you could lower your monthly premium if you increased that deductible, though? If you think about it, you pay the premiums monthly and only pay the deductible if you must use your insurance. It might make more sense to pay less per month and take your chance on a higher deductible. If you plan accordingly, you can have the deductible set aside in an emergency fund should it ever come down to needing it, while you save money on your monthly insurance bills.
                    </p>
                    <p>
                      <strong>Bundle your Policies</strong><br/>
                      Do you have insurance for more than your home? Maybe you have car insurance or life insurance. Your insurance company may offer a bundling discount if you get more than one policy from them. Insurance companies appreciate loyalty and tend to reward their clients that give them more than one thing to insure.
                    </p>
                    <p>
                    The most typical bundle is homeowner’s insurance and car insurance, but you can talk to your agent about other bundling discounts that may be available to you.
                    </p>
                    <p>
                      <strong>Decrease Your Home’s Risk</strong><br/>
                      The insurance company insures your home against loss, which is directly related to the risks your home is under. There are ways that you can decrease the risk, though. A few common examples include:
                      <ul>
                        <li>Reducing the risk of fire with updated electrical systems</li>
                        <li>Adding a security system to prevent theft</li>
                        <li>Adding certain precautions to prevent against weather damage, especially wind or water damage</li>
                      </ul>
                    </p>
                    <p>
                    Before you make any changes to your home, make sure you discuss the changes with your insurance company. What you may see as a way to lower your home’s risk may not hold the same value in the eyes of the insurance agent.
                    </p>
                    <p>
                      <strong>Ask About Age Discounts</strong><br/>
                      Some insurance companies provide discounts to retired clients because their risk of a claim on homeowner’s insurance goes down. Retired people tend to be home more often. This may lower the risk of burglary or a total loss in a fire. If you are home when a fire starts, you are better able to alert the fire department quickly, which may minimize the damage that occurs to the home rather than if it occurred when no one was home.
                    </p>
                    <p>
                      <strong>Don’t Have a Risky Past</strong><br/>
                      Insurance companies look at your insurance past to determine your risk. Even if you had insurance with another company, a new insurance company could see your past claims. If you had a lot of them, you can expect your premiums to be higher or for the insurance company not to insure your home at all.
                    </p>
                    <p>
                    Sometimes it’s the home itself that disqualifies you for homeowner’s insurance or forces you to pay higher premiums. If you are thinking about buying a home, talk to the owner about their history of homeowner’s insurance claims. Have there been a lot of thefts, water damage, or wind damage that occurred to the home? The location of the home could be an issue, putting any insurance company at risk, which could automatically mean higher premiums.
                    </p>
                    <p>
                    Most importantly, you need to shop around for homeowner’s insurance. Don’t just take the first quote you get. How do you know how it compares to others in the area? Each insurance company has different underwriting requirements as each company can take different levels of risk. Obtain a few quotes so that you know which premiums are the lowest for the best coverage available.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="How Much Home Insurance Do You Typically Need?"
              src={
                "../images/homereliefsurvey_article3.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              How Much Home Insurance Do You Typically Need?
            </Typography>
            <p className={classes.articleExcerpt}>
              The best way to protect your home and your important household investments from theft, flood, or any human-induced or natural disaster is to get a proper home insurance policy. A homeowners insurance ...
            </p>
            <p className={classes.articleReadMore}>
              <Link onClick={() => setModalProps({
                isOpen: true,
                title: 'How Much Home Insurance Do You Typically Need?',
                description: 'How Much Home Insurance Do You Typically Need?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">How Much Home Insurance Do You Typically Need?</Typography>
                    <p>
                    By Chris Hamler December 6, 2017
                    </p>
                    <p>
                    The best way to protect your home and your important household investments from theft, flood, or any human-induced or natural disaster is to get a proper home insurance policy. A homeowners insurance can help you rebuild, replace, or defray costs in case of liability to others. How much do you really need to be adequately covered? This article helps you arrive at a good estimate about the amount of homeowners insurance coverage you should get.
                    </p>
                    <p>
                      <strong>The Basics</strong><br/>
                      Typically, your standard homeowners insurance policy provides six types of coverage:
                    </p>
                    <p>
                      <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Coverage</TableCell>
                                <TableCell>Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Dwelling</TableCell>
                                <TableCell>provides coverage for the home itself and any structures attached to it</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Personal possessions</TableCell>
                                <TableCell>provides coverage for any of your stolen possessions even when they are not in your home</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Loss of use</TableCell>
                                <TableCell>pays for your food, lodging, and other living expenses when you are on the period of rebuilding and your home is rendered temporarily uninhabitable</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Personal liability</TableCell>
                                <TableCell>A financial buffer for when you’re sued as a result of incurring damage to other’s property or causing personal injury</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Medical payments</TableCell>
                                <TableCell>foots the medical bills of an individual who is injured on your property</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Other structures</TableCell>
                                <TableCell>compensates for the damage to any structure not attached to your home</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                    </p>
                    <p>
                      Out of these types, the bulk of your insurance cost is determined by two coverages: dwelling coverage and personal possessions.
                    </p>
                    <p>
                      <strong>Dwelling Coverage</strong><br/>
                      Standard homeowners insurance policies readily provide compensation for damage due to fire, lightning, hail, explosions, etc. However, if you live in an area that is prone to flooding or is near a fault line, see to it that you get the right coverage for flood or earthquakes. If you are not sure if you are at risk, visit your local government agency to get information. The important thing to remember is that you should have a policy with enough coverage to compensate for the cost of rebuilding your home.
                    </p>
                    <p>
                    If you limit your insurance policy based on your mortgage, chances are the coverage may be lesser than the cost of rebuilding. You may also use the market price of your home which is more or less the same as the potential cost of rebuilding, though basing the amount of coverage using this can be inaccurate by a large margin. A home appraisal might be needed to determine the current value of the home.
                    </p>
                    <p>
                    Typically, there are two factors that impact the cost of rebuilding: (a) the local cost of construction and (b) the square footage of the structure. To arrive at an estimate, multiply the structure’s square footage (b) by the local, per-square-foot building costs (a).
                    </p>
                    <p>
                    The style of the home, its interior, and overall design and special features may also add up and contribute to the cost of rebuilding.
                    </p>
                    <p>
                      <strong>Coding laws</strong><br/>
                      If you live in an area that issues building codes, know that these rules are updated from time to time. If you don’t know that there’s been changes to the established rules and your home was damaged, your standard policy may not pay for the extra cost of rebuilding to fit the new laws.
                    </p>
                    <p>
                    To guard yourself against this risk, you can get an endorsement to your policy called Ordinance or Law. This coverage will compensate for the cost of rebuilding to the tune of the new coding rules.
                    </p>
                    <p>
                      <strong>Age of the property</strong><br/>
                      Most older homes have special features that are expensive to replace. If your home belongs to this category and it was damaged, your standard homeowners insurance may not be able to cover the total cost of replacement. In this kind of situation, the owner of an older home should be able to get a modified replacement policy which could compensate for the reconstruction of the property to its original features.
                    </p>
                    <p>
                      <strong>Inflation</strong><br/>
                      Like any economic product, construction cost can ramp up through the years due to inflation. After a disaster hits and your property was damaged, you may be surprised to find out that the amount of your insurance is now falling short of the total cost of rebuilding.
                    </p>
                    <p>
                      To protect yourself from this burden and source of anxiety, you may purchase either:
                      <ol>
                        <li>A guaranteed cost replacement policy which will cover the cost of reconstruction including inflation</li>
                        <li>An extended replacement cost policy which will pay you 20 percent more on top of your insurance policy limits</li>
                      </ol>
                    </p>
                    <p>
                      <strong>Personal Possessions Coverage</strong><br/>
                      Experts suggest that 50 to 75 percent of your dwelling coverage is a good estimate for your personal possessions coverage.
                    </p>
                    <p>
                    But is it really enough?
                    </p>
                    <p>
                    The best way to determine how much coverage you should get is to conduct an inventory of your possessions. This includes expensive gadgets, appliances, furniture, jewelry, etc. Your inventory should include the serial numbers of all items, if possible, and their purchase price. This inventory will help the process of claims easier in case you will be placed in the situation to do so.
                    </p>
                    <p>
                      When it comes to personal possessions, there are two types of coverage you can choose from:
                      <ul>
                        <li>
                        Actual cash value coverage where you get compensated for the cost of the items based on how old they are. Actual cash value is equal to the replacement cost minus any depreciation (ACV = replacement cost – depreciation.
                        </li>
                        <li>
                        Replacement cost coverage which would pay for the actual purchase cost of the items. The replacement cost is usually calculated using the initial price tag paid for the items regardless of any potential depreciation.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>Do I need something else?</strong><br/>
                      What isn’t recommended doesn’t mean it isn’t needed. If your provider doesn’t tell you to get flood insurance coverage, it doesn’t mean you shouldn’t get one. Just recently, the cyclones that hit major parts of the US have left thousands of households helpless without the proper flood insurance coverage. In the middle of a disaster, the last thing you need is worrying about the cost of rebuilding especially if you’re still paying your mortgage.
                    </p>
                    <p>
                    Another cost to consider is your deductible. In a nutshell, the deductible is the money you have to pay from your own wallet every time you submit a claim on your policy. The higher the amount you pay on your deductible, the lower your premium will be.
                    </p>
                    <p>
                    When calculating the amount of coverage you’d need, your provider would usually help you arrive at a figure. However, there’s no cookie cutter formula for a precise estimation. It helps to have a background about where the costs are coming from and how it is calculated to be able to get a more reliable number.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const Steps = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer}>
        <Typography variant="h2" component="h2" className={classes.stepsHeading}>
          We Bring The Pros To You In <br/>
          3 Simple Steps
        </Typography>
        <Grid container spacing={10} justify="center" className={classes.tipsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={ScriptEditorImage} alt="Fill us in." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Fill us in.
            </Typography>
            <p className={classes.stepText}>
              Share some basic info to get customized service.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={PhotoGalleryImage} alt="Take your pick." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Take your pick.
            </Typography>
            <p className={classes.stepText}>
              Compare best estimates from top companies.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={LoginImage} alt="Ink your deal." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Ink your deal.
            </Typography>
            <p className={classes.stepText}>
              Connect with a local agent to get a quote or apply online.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const FooterGetQuote = () =>  {
  const classes = muiStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.footerGetQuote}>
      <Grid item xs={10} lg={7} xl={5}>
        <GetQuoteForm className={classes.getQuoteForm} inputErrorClassName={classes.footerGetQuoteInputError} inputLayout="inline" />
      </Grid>
    </Grid>
  );
};

const IndexPage = () => {
  const classes = muiStyles();

  const [ modalProps, setModalProps ] = useState({ isOpen: false, title: '', description: '', content: ''});

  const closeModal = () =>{
    setModalProps({ isOpen: false, title: '', description: '', content: ''});
  }

  return (
    <Layout>
      <Hero />
      <Tips />
      <Blogs setModalProps={setModalProps} />
      <Steps />
      <FooterGetQuote />
      <Modal
        open={modalProps.isOpen}
        onClose={closeModal}
        className={classes.modal}
        id="blog-modal"
        disableBackdropClick
      >
        
        <div className={classes.modalWrapper}>
          <Container className={classes.modalContent}>
            <CancelIcon className={classes.modalCloseIcon} onClick={closeModal}/>
              {modalProps.content}
          </Container>
        </div>
      </Modal>
    </Layout>
  )
};

export default IndexPage;
